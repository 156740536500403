<div class="cf-container-customer-listing">
  <div class="addcustomer">
    <h1></h1>
    <button mat-button color="primary" class="addCustomerBtn" (click)="openCustomerDialog()">
      <mat-icon>add_circle</mat-icon>Add Customer
    </button>
  </div>
  <div class="cf-customer-list">
    <div class="table-responsive">
      <table class="customer-table">
        <thead>
          <tr>
            <th>SL No.</th>
            <th>Customer Name</th>
            <th>Sanction Limit</th>
            <th>Utilized Limit</th>
            <th>Interest Rate</th>
            <th>Status</th>
            <th>View Lender</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let customerLoan of customerAppliedLoans; let i = index;">
            <td>{{i+1}}</td>
            <td class="customerDetail" (click)="openAccountDetails(customerLoan?.buyerId)">
              {{customerLoan?.customerName}}
            </td>
            <td>{{customerLoan?.sanctionLimit || '-'}}</td>
            <td>{{customerLoan?.utilizedLimit || '-'}}</td>
            <td>{{customerLoan.interestRate || '-'}}</td>
            <td>
              {{
              customerLoan.status == loanstatus.PENDING_SANCTION ? loanstatus.PENDING :
              customerLoan.status == loanstatus.PENDING_DISBURSEMENT ? loanstatus.PENDING :
              customerLoan.status == loanstatus.APPROVED_SANCTION ? loanstatus.APPROVED :
              customerLoan.status == loanstatus.APPROVED_DISBURSEMENT ? loanstatus.APPROVED :
              customerLoan.status == loanstatus.INACTIVE ? loanstatus.INACTIVE : '-'
              }}
            </td>
            <td>
              <mat-icon (click)="openLenderList(customerLoan.buyerId)">people</mat-icon>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>