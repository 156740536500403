import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { LoanStatus } from '../../model/loan-status';
import { DashboardService } from '../dashboard.service';
import { BuyerDisbursementDialogComponent } from './buyer-disbursement-dialog/buyer-disbursement-dialog.component';
import { BuyerLoanDailogComponent } from './buyer-loan-dailog/buyer-loan-dailog.component';
import { SpocDialogComponent } from './spoc-dialog/spoc-dialog.component';
import { ToastService } from '../../services/toast.service';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LoadingService } from '../../services/loader.service';
import { MatIconModule } from '@angular/material/icon';
import { InterestRecordComponent } from './interest-record/interest-record.component';

@Component({
  selector: 'app-buyer-dashboard',
  standalone: true,
  imports: [CommonModule, MatTableModule, MatButtonModule, MatFormFieldModule, MatInputModule, MatIconModule],
  providers: [DashboardService],
  templateUrl: './buyer-dashboard.component.html',
  styleUrl: './buyer-dashboard.component.scss'
})
export class BuyerDashboardComponent implements OnInit {
  isCustomerKycVerified: boolean;
  isLoanFound: boolean

  dataSource: LenderList[];
  financierData = []

  loanStatuses = LoanStatus;
  initiateDisbursementObject: any
  contactPersonViews: any
  allAppliedLoans: [];
  initiateDisbursementUrl: string
  displayedColumns = ['slNo', 'bankId', 'interestRate', 'spoc', 'currentStatus', 'action']
  userDetail: any;
  dashboardData: any;

  constructor(
    private dashboardService: DashboardService,
    public dialog: MatDialog,
    private toasterService: ToastService,
    private loadingService: LoadingService) {

  }

  ngOnInit() {
    this.fetchLenderDetails();
    this.getAccountDetails();
    this.isKycVerified();
    this.getbuyerDashboardDetails();
  }

  getbuyerDashboardDetails() {
    this.dashboardService.getDashboardDetails().subscribe(data => {
      this.dashboardData = data['result'];
    });
  }

  viewLenderContact(lenderDetails): void {
    const dialogRef = this.dialog.open(SpocDialogComponent, {
      maxWidth: '600px',
      width: 'auto',
      data: lenderDetails
    });
    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  getAccountDetails() {
    this.dashboardService.getUserAccountDetails().subscribe({
      next: (data) => {
        if (data && data['status'] === 200) {
          this.userDetail = data.result;
        }
        else {
          this.toasterService.show('Error', data['message']);
        }
      },
      error: (error) => {
        this.userDetail = null;
      }
    });
  }

  isKycVerified() {
    this.isKycVerified = this.dashboardService.isKycVerified();
  }


  fetchLenderDetails() {
    this.dashboardService.getbuyerAccountDetails().subscribe(
      (res) => {
        if (res['status'] === 200) {
          this.dataSource = res.result;
        }
        else if (res['status'] === 400) {
          this.toasterService.show('Error', res?.message);
        }
      },
      (error) => {
        this.financierData = [];
        this.toasterService.show('Error', 'Lenders are not Present');

      }
    );
  }

  changeToPendingDisbursement(loanId: string, status: string, disbursementUrl): void {
    this.initiateDisbursementObject = {
      "loanId": loanId,
      "currentStatus": status
    }
    this.initiateDisbursementUrl = disbursementUrl
  }

  openbuyerLoanDialog(element) {
    const dialogRef = this.dialog.open(BuyerLoanDailogComponent, {
      width: "1000px",
      height: "550px",
      data: element
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.fetchLenderDetails();
    });
  }

  openInitiatePaymentDialog(element) {
    const dialogRef = this.dialog.open(BuyerDisbursementDialogComponent, {
      width: "900px",
      height: "570px",
      data: element
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.submitInitiateDisbursement(result);
      }
    });
  }

  submitInitiateDisbursement(data) {
    this.dashboardService.initiateDisbursement(data).subscribe(
      res => {
        if (res['status'] == 200) {
          this.toasterService.show('Success', 'Disbursenment Initiated');
        }
        else {
          this.toasterService.show('Error', 'Disbursenment Failed');
        }
      },
      error => {
        // Handle any errors that occur during the API call
        this.toasterService.show('Error', 'An error occurred: ' + error.message);
        this.loadingService.setLoadingState(false);
      },
      () => {
        // This is the completion callback, which is called after the Observable completes
        this.loadingService.setLoadingState(false);
      }
    );
  }

  interestModel(lenderId, supplierId) {
    const dialogRef = this.dialog.open(InterestRecordComponent, {
      maxWidth: '600px',
      height: "auto",
      data: {
        lenderId: lenderId,
        supplierId: supplierId
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      // this.fetchLenderDetails;
    });
  }
}


export interface LenderList {
  'bankName': string,
  'interestRate': Number,
  'spoc': Number,
  'currentStatus': string,
  'action': string,
  'bankId': string
}


