import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { DashboardService } from '../../dashboard.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DataService } from '../../../data.service';
import { HttpClientModule } from '@angular/common/http';
import { ToastService } from '../../../services/toast.service';
import { LoadingService } from '../../../services/loader.service';
@Component({
  selector: 'app-buyer-loan-dailog',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    HttpClientModule
  ],
  providers: [DashboardService, DataService],
  templateUrl: './buyer-loan-dailog.component.html',
  styleUrl: './buyer-loan-dailog.component.scss'
})
export class BuyerLoanDailogComponent implements OnInit {
  isSaved: any;
  gstregistration: any;
  partenershipDead: any;
  pan: any;
  iec: any;
  bankStatement: any;
  balancesheet: any;
  itrReport: any;
  termLoan: any;
  loanRequestForm: any;
  gstReport: any;
  agreeTerms: any;

  gstReportName: string;
  gstregistrationName: string;
  partenershipDeadName: string;
  panName: string;
  iecName: string;
  bankStatementName: string;
  termLoanName: string;
  balancesheetName: string;
  itrReportName: string;
  limitRequired: number;
  lenderData: any;
  reportResponce: any;

  constructor(
    private fb: FormBuilder,
    private dashboardService: DashboardService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialogRef<BuyerLoanDailogComponent>,
    private toastService: ToastService,
    private loadingService: LoadingService
  ) {
  }

  ngOnInit() {
    this.initForms();
    this.getFinancierDocsDetails();
    console.log(this.data)
    this.lenderData = this.data;
  }
  initForms() {
    this.loanRequestForm = this.fb.group({
      companyName: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      gstIn: ['', Validators.required],
      emailId: ['', Validators.required],
    });
  }
  onFileChanged($event: Event, DocumentName: string) {
    this.fileUpload(event, DocumentName);
  }

  applyFinancingRequest() {
    let obj = {
      limitRequired: this.limitRequired,
      lenderId: this.lenderData.lenderId,
      supplierId: this.lenderData.supplierId
    }

    this.dashboardService.applyFinanceReq(obj).subscribe(res => {
      if (res['status'] == 200) {
        this.dialog.close();

        this.toastService.show('Success', 'Finance Requested');
      }
    })
  }
  fileUpload(event, DocName) {
    let fileToUpload = event.target.files.item(0);

    let xhr = this.dashboardService.uploadDocs(fileToUpload, DocName).subscribe();

    xhr.onreadystatechange = (function (request: XMLHttpRequest, event: Event) {
      if (request.status === 200 && request.responseText) {
        let res = JSON.parse(request.responseText);
      }
    }).bind(this, xhr);
  }
  clearStack(event: any) {
    event.target.value = null;
  }

  closePopup() {
    this.dialog.close();
  }

  triggerFileInput(buttonName) {
    this['buttonName'].nativeElement.click();
  }

  onSubmit() {

  }

  getFinancierDocsDetails() {
    this.dashboardService.getFinancierDocsDetails().subscribe({
      next: (results) => {
        if (results['status'] == 200) {
          this.setBuyerDetails(results['result']);
          this.reportResponce = results['result'];

        } else {
          this.toastService.show('Error', results['message']);

        }
      },
      error: (error) => {
        console.log(error);

      },
    });
  }

  removeFile(docName) {

  }

  setBuyerDetails(result) {
    this.gstReportName = result['gstReport'] ? result['gstReport'] : undefined;
    this.gstregistrationName = result['gstregistration'] ? result['gstregistration'] : undefined;
    this.partenershipDeadName = result['moa'] ? result['moa'] : undefined;
    this.panName = result['panDoc'] ? result['panDoc'] : undefined;
    this.iecName = result['iecCertificate'] ? result['iecCertificate'] : undefined;
    this.bankStatementName = result['banckStatement'] ? result['banckStatement'] : undefined;
    this.termLoanName = result['termLoanDetail'] ? result['termLoanDetail'] : undefined;
    this.balancesheetName = result['auditBS'] ? result['auditBS'] : undefined;
    this.itrReportName = result['itrReport'] ? result['itrReport'] : undefined;
  }

  downloadFile(docName) {
    window.open(this.reportResponce[docName])
  }
  get checkDocs() {
    return this.gstReportName && this.gstregistrationName && this.partenershipDeadName
  }
}
