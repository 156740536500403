<div class="cf-container-supplier">
  <div class="row-box">
    <div class="square-box mr-20">
      <p>All Lenders</p>
      <!-- <span class="no-span">5545</span> -->
      <button class="rc-invoice-btn">5 Lenders
        <span><i class="ri-arrow-down-s-line"></i></span>
      </button>
    </div>
    <div class="square-box mr-20">
      <p>All Customers</p>
      <!-- <span class="no-span"> 10</span> -->
      <button class="rc-invoice-btn">4 Customers
        <span><i class="ri-arrow-down-s-line"></i></span>
      </button>
    </div>
    <div class="square-box mr-20">
      <p>Active Customers</p>
      <!-- <span class="no-span"> 23</span> -->
      <button class="rc-invoice-btn"> 4 Customers
        <span><i class="ri-arrow-down-s-line"></i></span>
      </button>
    </div>
    <div class="square-box mr-20">
      <p>Interest in Percent</p>
      <!-- <span class="no-span">23</span><br /> -->
      <button class="rc-invoice-btn">14 Avg ROI
        <span><i class="ri-arrow-down-s-line"></i></span>
      </button>
    </div>
    <div class="right-wrap fg-1">
      <div class="radio-card p-0 not-cursor">
        <div class="w-50p flex-center max-width-50p fg-1">
          <div class="sp-title">Spend Progress</div>
          <div class="progress-indicator">
            <div class="progress-value-center">
            </div>
            <div class="progress-value-center">
              0%
            </div>
          </div>
        </div>
        <div class="w-50p limits-wrap fg-1">
          <div class="limit-wrap">
            <div class="limits-header">Total Limit</div>
            <div class="limits-value">
              ₹
            </div>
          </div>
          <div class="limit-wrap bottom-border">
            <div class="limits-header">Utilized Limit</div>
            <div class="limits-value">
              ₹
            </div>
          </div>
          <div class="limit-wrap">
            <div class="limits-header">Available Limit</div>
            <div class="limits-value">
              ₹
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section class="cf-lenders-main">
    <div class="cf-lender-list">
      <h2> Lender List </h2>
      <div class="table-responsive">
        <table>
          <thead class="th-bg">
            <tr>
              <th>SL No.</th>
              <th class="finance">Financing Partner</th>
              <th class="random-row">Sanction Limit</th>
              <th class="random-row">Utilized Limit</th>
              <th class="random-row">Interest Rate</th>
              <th class="random-row">Total Customer using CF</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let financier of financierData;let i = index;">
              <td>{{i+1}}</td>
              <td>
                <a (click)="openGetKycDetail(financier.lenderId)" class="view">{{financier.financierName}}</a>
              </td>
              <td>{{financier.limit ? financier.limit?.toFixed(2) : '-'}} </td>
              <td>{{financier.utilizedLimit ? financier.utilizedLimit?.toFixed(2) : '-'}}</td>
              <td>{{financier.interestRate ? financier.interestRate?.toFixed(2) : '-'}}%</td>
              <td>
                <h4>
                  <!-- {{financier.totalBankCustomerCount ? financier.totalBankCustomerCount : '-'}} -->
                  <a [ngClass]="financier.totalLoanRequestCount == 0 ?'changes':'changes2'">
                    <mat-icon (click)="showBuyersOfCurrentLender(financier)">people</mat-icon>
                  </a>
                </h4>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</div>