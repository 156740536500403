import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { LoanStatus } from '../model/loan-status';
import { AddCusomerDialogComponent } from './add-cusomer-dialog/add-cusomer-dialog.component';
import { CustomerService } from './customer.service';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { LoadingService } from '../services/loader.service';
import { LenderListDialogComponent } from './lender-list-dialog/lender-list-dialog.component';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastService } from '../services/toast.service';

@Component({
  selector: 'app-customer',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule],
  providers: [
    CustomerService,
    AuthService,
  ],
  templateUrl: './customer.component.html',
  styleUrl: './customer.component.scss',
})
export class CustomerComponent implements OnInit {
  loanstatus: any = LoanStatus;
  customerAppliedLoans = [];
  userId: string;

  constructor(
    public dialog: MatDialog,
    private customerService: CustomerService,
    private authService: AuthService,
    private router: Router,
    private loadingService: LoadingService,
    private toasterService: ToastService
  ) { }

  ngOnInit() {
    this.initialLoadingDetails();
  }

  initialLoadingDetails() {
    if (this.isBuyerUser()) {
      this.getBuyerCustomersDetails();
    } else {
      this.getSupplierCustomerDetails();
    }
  }

  openCustomerDialog() {
    this.toasterService.resetToaster();
    const dialogRef = this.dialog.open(AddCusomerDialogComponent, {
      width: '560px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.onSubmit(result);
      }
    });
  }

  onSubmit(newCustomerData) {
    this.loadingService.setLoadingState(true);
    this.customerService.addCustomer(newCustomerData).subscribe({
      next: (res) => {
        if (res && res['status'] == 200) {
          this.toasterService.show(
            'Success',
            'Buyer has been Onborded Succssfully'
          );
          this.initialLoadingDetails();
          this.loadingService.setLoadingState(false);
        } else {
          this.loadingService.setLoadingState(false);
          this.toasterService.show('Error', res?.['message']);
        }
      },
      error: (error) => {
        this.loadingService.setLoadingState(false);
        this.toasterService.show('Error', 'Something went Wrong');
      },
    });
  }

  isBuyerUser() {
    return this.authService.isbuyerUser();
  }
  isSupplieruser() {
    return this.authService.isSupplierUser();
  }

  getBuyerCustomersDetails() {
    this.customerService.getBuyerCustomersDetails().subscribe((responce) => {
      if (responce['status'] === 200) {
        this.customerAppliedLoans = responce['result'];
      } else {
        this.toasterService.show('Error', "Customer not found")
      }
    });
  }
  getSupplierCustomerDetails() {
    this.customerService.getSupplierCustomersDetails().subscribe({
      next: (response) => {
        if (response && response['status'] === 200) {
          this.customerAppliedLoans = response['result'];
        } else {
          this.toasterService.show('Error', 'Customer not Found');
        }
      },
      error: (err) => {
        // Handle any error that occurred in any of the previous Observables.
        this.toasterService.show('Error', 'An error occurred');
        // You can also use err to display a specific error message
      },
      complete: () => {

      }
    });
  }

  openAccountDetails(userId: string) {
    this.router.navigate([`/customer-list/${userId}`]);
  }

  openLenderList(buyerId: string) {
    console.log(buyerId);
    const dialogRef = this.dialog.open(LenderListDialogComponent, {
      width: 'auto',
      height: 'auto',
      data: { buyerId: buyerId },
      position: {
        top: '170px',
      },
    });
    // dialogRef.afterClosed().subscribe((result) => {
    // });
  }
}
