<div class="cf-container">
  <section class="cf-lenders-main">
    <div class="row-box mb-30">
      <div class="square-box mr-20">
        <p>All Lenders</p>
        <!-- <span class="no-span">₹ 0</span> -->
        <button class="rc-invoice-btn">5 Lenders
          <span><i class="ri-arrow-down-s-line"></i></span>
        </button>
      </div>
      <div class="square-box mr-20">
        <p>All Customers</p>
        <!-- <span class="no-span">₹ 0</span> -->
        <button class="rc-invoice-btn"> Customers
          <span><i class="ri-arrow-down-s-line"></i></span>
        </button>
      </div>
      <div class="square-box mr-20">
        <p>Active Customers</p>
        <!-- <span class="no-span">₹ 0</span> -->
        <button class="rc-invoice-btn"> Customers
          <span><i class="ri-arrow-down-s-line"></i></span>
        </button>
      </div>
      <div class="square-box mr-20">
        <p>Interest in Percent</p>
        <!-- <span class="no-span">₹ 0</span><br /> -->
        <button class="rc-invoice-btn">14 Avg ROI
          <span><i class="ri-arrow-down-s-line"></i></span>
        </button>
      </div>
      <div class="right-wrap fg-1">
        <div class="radio-card p-0 not-cursor">
          <div class="w-50p flex-center max-width-50p fg-1">
            <div class="sp-title">Spend Progress</div>
            <div class="progress-indicator">
              <div class="progress-value-center">
              </div>
              <div class="progress-value-center">
                0%
              </div>
            </div>
          </div>
          <div class="w-50p limits-wrap fg-1">
            <div class="limit-wrap">
              <div class="limits-header">Total Limit</div>
              <div class="limits-value">
                ₹
              </div>
            </div>
            <div class="limit-wrap bottom-border">
              <div class="limits-header">Utilized Limit</div>
              <div class="limits-value">
                ₹
              </div>
            </div>
            <div class="limit-wrap">
              <div class="limits-header">Available Limit</div>
              <div class="limits-value">
                ₹
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cf-lender-list">
      <div class="table-responsive">
        <h1 class="lender-list-title"> Lender List </h1>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 custom-mat-table">

          <!-- Define the columns -->
          <ng-container matColumnDef="slNo">
            <th mat-header-cell *matHeaderCellDef> SL No. </th>
            <td mat-cell *matCellDef="let element; let i = index"> {{i + 1}} </td>
          </ng-container>

          <ng-container matColumnDef="bankId">
            <th mat-header-cell *matHeaderCellDef> Financing Partner </th>
            <td mat-cell *matCellDef="let element">{{element.bankName}} </td>
          </ng-container>

          <ng-container matColumnDef="interestRate">
            <th mat-header-cell *matHeaderCellDef> Interest Rate </th>
            <td mat-cell *matCellDef="let element">
              <span class="interestRate">
                {{element.interestRate}}
                %
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="spoc">
            <th mat-header-cell *matHeaderCellDef> SPOC </th>
            <td mat-cell *matCellDef="let element">
              <a (click)="viewLenderContact(element.spoc)" [ngClass]="(element.status == loanStatuses.NOT_YET_APPLIED ||
              element.status == loanStatuses.PENDING_SANCTION) ? 'disabledView' : 'enableView'">
                VIEW
              </a>
            </td>
          </ng-container>

          <ng-container matColumnDef="currentStatus">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element">
              <!-- [ngClass]="{
              'status': element.currentStatus == loanStatuses.NOT_YET_APPLIED,
              'status status-red': element.currentStatus == loanStatuses.PENDING_SANCTION,
              'status status-green': element.currentStatus == loanStatuses.APPROVED_SANCTION,
              'status status-red': element.currentStatus == loanStatuses.PENDING_DISBURSEMENT,
              'status status-green': element.currentStatus == loanStatuses.APPROVED_DISBURSEMENT
              }" -->
              {{element.status ? element.status : 'Not yet Applied'}}
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element">
              @if((element.status) == loanStatuses.NOT_YET_APPLIED || !element.status){
              <ng-container>
                <button mat-button data-toggle="modal" data-target="#RequestConfirmationModal" class="actionBtn"
                  (click)="openbuyerLoanDialog(element)" [ngClass]="element.action ? 'hide' : ''">
                  {{loanStatuses.APPLY}}
                </button>
              </ng-container>
              }
              @else if(element.status != loanStatuses.NOT_YET_APPLIED){
              <ng-container>
                <!-- (click)="changeToPendingDisbursement(element?.financeBazaarLoanId, loanStatuses.APPROVED_DISBURSEMENT, element?.disbursementUrl)" -->
                <button mat-button [disabled]="element.status == 'PENDING'" class="actionBtn"
                  (click)="openInitiatePaymentDialog(element)">
                  {{
                  element.status == loanStatuses.PENDING_SANCTION ? loanStatuses.APPLY :
                  element.status == loanStatuses.APPROVED_SANCTION ?
                  loanStatuses.INITIATE_DISBURSEMENT :
                  element.status == loanStatuses.APPROVED_DISBURSEMENT ?
                  loanStatuses.INITIATE_DISBURSEMENT :
                  element.status == 'PENDING' ? 'Apply' : element.status == 'APPROVED' ? 'Initiate Disbursement' :
                  element.status
                  }}
                </button>
              </ng-container>
              }
            </td>
          </ng-container>

          <!-- Define the table rows using the defined columns -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

      </div>
    </div>
  </section>