<div class="main-profile-section">
  <div class="profile-header">
    <div class="icon-flex-box">
      <img src="/assets/imgs/profile-icon.png" />
      <div class="profile">
        <h4>{{userDetail.companyName}}, <br /><span>Welcome to Profile overview</span></h4>
      </div>
    </div>
    <!-- <div class="tooltip-btn">
      <button mat-raised-button matTooltip="Info about the action"
        aria-label="Button that displays a tooltip when focused or hovered over">
        <span><i class="ri-information-line"></i></span>
        Help and support
      </button>
    </div> -->
    <div class="account-tabs-sec" #activeKycTabOnKycDone>
      <ul class="nav nav-tabs" role="tablist">
        @if(isBuyerUser()){
        <li class="nav-item" [ngClass]="{'active': isCustomerKycDone}">
          <a class="nav-link" (click)="kycComp()" aria-controls="kycVerification" role="tab" data-toggle="tab">
            <i class="ri-user-follow-line"></i> KYC for Financing
          </a>
        </li>
        }
      </ul>
    </div>
  </div>
  <div class="tab-box">
    <div class="custom-tab-container">
      <div class="tab-left">
        <mat-tab-group>
          <mat-tab label="Business Details">
            <div class="flex-box">
              <mat-form-field class="mog3_floatmatinput mt-05 mr-15" appearance="outline" floatLabel="always">
                <mat-label>Company Name*</mat-label>
                <input matInput placeholder="Company Name" [(ngModel)]="userDetail.companyName" [disabled]="isEditable">
              </mat-form-field>
              <mat-form-field class="mog3_floatmatinput mt-05 mr-15" appearance="outline" floatLabel="always">
                <mat-label>Email ID*</mat-label>
                <input matInput placeholder="Email ID" [(ngModel)]="userDetail.email" [disabled]="isEditable">
                <!-- <mat-icon>edit</mat-icon> -->
              </mat-form-field>
              <mat-form-field class="mog3_floatmatinput mt-05 mr-15" appearance="outline" floatLabel="always">
                <mat-label>GSTIN*</mat-label>
                <input matInput placeholder="GSTIN" [(ngModel)]="userDetail.gstIn" [disabled]="isEditable">
              </mat-form-field>
              <mat-form-field class="mog3_floatmatinput mt-05 mr-15" appearance="outline" floatLabel="always">
                <mat-label>Pan No.*</mat-label>
                <input matInput placeholder="Pan Number" [(ngModel)]="userDetail.pan" [disabled]="isEditable">
                <!-- <mat-icon>edit</mat-icon> -->
              </mat-form-field>
              <mat-form-field class="mt-05 mr-15 mog3_floatmatinput" appearance="outline" floatLabel="always">
                <mat-label>Country*</mat-label>
                <mat-select [(ngModel)]="selectedValue" name="food" [disabled]="isEditable">
                  @for (country of countryList; track country) {
                  <mat-option [value]="country">{{country}}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
          </mat-tab>
          <!-- <mat-tab label="Second"> Content 2 </mat-tab>
            <mat-tab label="Third"> Content 3 </mat-tab> -->
        </mat-tab-group>
      </div>
      <!-- <div class="btn-right">
          <button class="btn-tab mr-15" (click)="prevTab()" [class.active]="isPrevActive"><span class="prev"><i class="ri-arrow-left-s-line"></i></span></button>
          <button class="btn-tab" (click)="nextTab()" [class.active]="isNextActive"> <span class="next"><i class="ri-arrow-right-s-line"></i></span></button>
        </div> -->
    </div>
    <button mat-button color="primary" class="editBankBtn" (click)="EditAccountDetails()">{{isEditable == true ? 'Edit'
      : 'Save'}}</button>
  </div>
</div>


<div class="cf-lender-details">
  @if(isBuyerUser()){
  <div class="bankHeader">
    <h3> <mat-icon>account_balance</mat-icon>Bank Details</h3>
  </div>
  <div class="card-table">
    <div class="headerSec">
      <h5 class="card-heading">Bank Info</h5>
      <button mat-button color="primary" class="editBankBtn" (click)="addBank()">
        <mat-icon>add_circle</mat-icon>Add Bank
      </button>
    </div>
    <div class="card-content">
      <table mat-table [dataSource]="dataSource" class="custom-mat-table">

        <ng-container matColumnDef="accNumber">
          <th mat-header-cell *matHeaderCellDef> Account Number </th>
          <td mat-cell *matCellDef="let element"> {{element.accNo}} </td>
        </ng-container>

        <ng-container matColumnDef="bankName">
          <th mat-header-cell *matHeaderCellDef> Bank Name </th>
          <td mat-cell *matCellDef="let element"> {{element.bankName}} </td>
        </ng-container>

        <ng-container matColumnDef="branchName">
          <th mat-header-cell *matHeaderCellDef> Branch Name</th>
          <td mat-cell *matCellDef="let element"> {{element.branchName}} </td>
        </ng-container>

        <ng-container matColumnDef="ifscCode">
          <th mat-header-cell *matHeaderCellDef> IFSC Code </th>
          <td mat-cell *matCellDef="let element"> {{element.ifscCode}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
  }
</div>