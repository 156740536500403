import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { CustomerService } from '../customer.service';
import { DataService } from '../../data.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastService } from './../../services/toast.service';
import { LoadingService } from '../../services/loader.service';
import { LoaderComponent } from '../../shared/loader/loader.component';
import { ToastContainerComponent } from '../../shared/toast-container/toast-container.component';

@Component({
  selector: 'app-add-cusomer-dialog',
  standalone: true,
  imports: [
    LoaderComponent,
    ToastContainerComponent,
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
  ],
  providers: [CustomerService, DataService],
  templateUrl: './add-cusomer-dialog.component.html',
  styleUrl: './add-cusomer-dialog.component.scss',
})
export class AddCusomerDialogComponent implements OnInit {
  customerForm: FormGroup;
  lenderDetails: any;
  data: any;
  hide: any = true;
  filteredSuppliers: any;

  constructor(
    private fb: FormBuilder,
    private customerService: CustomerService,
    private dialogRef: MatDialogRef<AddCusomerDialogComponent>,
    public toasterService: ToastService,
    public loadingService: LoadingService
  ) { }
  ngOnInit() {
    this.customerForm = this.fb.group({
      companyName: [null, Validators.required],
      phoneNumber: [null, Validators.required],
      gstIn: [null, Validators.required],
      emailId: [null, Validators.required],
    });
  }

  onSubmit() {
    if (!this.customerForm.valid) return;
    this.dialogRef.close(this.customerForm.value);

  }
}
