import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { DataService } from '../../data.service';
import { MyAccountService } from '../myAccount.service';
import { MatTabsModule, matTabsAnimations } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import moment from 'moment';
import { LoadingService } from '../../services/loader.service';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-kyc-document',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, MatButtonModule, MatIconModule, MatTabsModule, MatInputModule, MatFormFieldModule, MatDatepickerModule, MatNativeDateModule, MatSelectModule],
  providers: [DataService, MyAccountService, LoadingService],
  templateUrl: './kyc-document.component.html',
  styleUrl: './kyc-document.component.scss',
})
export class KycDocumentComponent implements OnInit {
  @Input('isSaved') isSaved = true;

  businessDetailsForm: FormGroup;
  contactDetailsForm: FormGroup;
  promotersDetailsForm: FormGroup;
  selectedValue: string;
  countryList = ['india', 'china', 'US', 'Other'];
  buyerKyc = [];
  businessNature: String;
  items: any;
  contactDetails: ContactDetails[] = [];
  basicDetailShouldDisplay: any = false;
  isCustomerKycDone: any = true;
  //docs
  gstReport: any;
  gstregistration: any;
  partenershipDead: any
  tempdocname: any;
  pan: any;
  iec: any
  banckStatement: any;
  termLoan: any
  balancesheet: any
  itrReport: any

  docFiles: any = {};
  gstReportUrl: any;
  gstregistrationUrl: any;
  partenershipDeadUrl: any;
  panUrl: any;
  iecUrl: any;
  banckStatementUrl: any;
  termLoanUrl: any;
  itrReportUrl: any;
  balancesheetUrl: any;
  kycData: any;
  isSaveButtonVisible: boolean = true;

  constructor(
    private bd: FormBuilder,
    private router: Router,
    private accountService: MyAccountService,
    private loadingService: LoadingService,
    private toasterService: ToastService
  ) {

  }

  ngOnInit() {
    this.setInitForms();
    this.initContactDetails();
    this.getKycData();
  }

  setInitForms() {
    this.businessDetailsForm = this.bd.group({
      companyName: [null, Validators.required],
      dateOfCom: [null, Validators.required],
      incorporationDate: [null, Validators.required],
      zipCode: [null, Validators.required],
      panNo: [null, Validators.required],
      state: [null, Validators.required],
      address: [null, Validators.required],
      gstin: [null, Validators.required],
      businessInvolved: [null, Validators.required],
      businessNature: [null, Validators.required],
      iecNumber: [null, Validators.required],
      vinatageInBusiness: [null, Validators.required],
      turnOver: [null, Validators.required],
    });

    this.promotersDetailsForm = this.bd.group({
      promoterName: [null, Validators.required],
      kycNumber: [null, Validators.required],
      associationDate: [null, Validators.required],
      dob: [null, Validators.required],
      state: [null, Validators.required],
      gender: [null, Validators.required],
      nationality: [null, Validators.required],
      residence: [null, Validators.required],
      address: [null, Validators.required],
      phoneNo: [null, Validators.required],
      zipCode: [null, Validators.required],
      email: [null, Validators.required],
    });
  }

  initContactDetails() {
    let tempObj = {
      fullName: null,
      phoneNumber: null,
      emailId: null
    };
    this.contactDetails.push(tempObj);
  }
  submitKycDoc() {
    if (Object.keys(this.docFiles).length != 9) {
      this.toasterService.show('Error', 'Required Document is not Uploaded');
      return;
    }
    let obj = {
      buyerKyc: this.getBusinessDetails(this.businessDetailsForm.controls),
      buyerContact: this.contactDetails,
      buyerPromoter: this.getPromorsDetails(this.promotersDetailsForm.controls),
      kycDocument: this.docFiles
    };

    this.accountService.saveKycDetails(obj).subscribe((res) => {
      if (res) {
        this.isSaveButtonVisible = false;
        this.businessDetailsForm.disable();
        this.promotersDetailsForm.disable();
        this.toasterService.show('Success', 'Saved Succesfully')
      }
    });
  }
  getBusinessDetails(businessDetailsForm) {
    return {
      companyName: businessDetailsForm.companyName.value,
      dateOfCom: moment(businessDetailsForm.dateOfCom.value).valueOf(),
      incorporationDate: moment(businessDetailsForm.incorporationDate).valueOf(),
      zipCode: businessDetailsForm.zipCode.value,
      panNo: businessDetailsForm.panNo.value,
      state: businessDetailsForm.state.value,
      address: businessDetailsForm.address.value,
      gstin: businessDetailsForm.businessInvolved.value,
      businessInvolved: businessDetailsForm.businessInvolved.value,
      businessNature: businessDetailsForm.businessNature.value,
      iecNumber: businessDetailsForm.iecNumber.value,
      vinatageInBusiness: businessDetailsForm.vinatageInBusiness.value,
      turnOver: businessDetailsForm.turnOver.value,
    }
  }

  getPromorsDetails(promotersDetailsForm) {
    return {
      promoterName: promotersDetailsForm.promoterName.value,
      kycNumber: promotersDetailsForm.kycNumber.value,
      associationDate: moment(promotersDetailsForm.associationDate).valueOf(),
      dob: moment(promotersDetailsForm.dob.value).valueOf(),
      state: promotersDetailsForm.state.value,
      gender: promotersDetailsForm.gender.value,
      nationality: promotersDetailsForm.nationality.value,
      residence: promotersDetailsForm.residence.value,
      address: promotersDetailsForm.address.value,
      phoneNo: promotersDetailsForm.phoneNo.value,
      zipCode: promotersDetailsForm.zipCode.value,
      email: promotersDetailsForm.email.value,
    }
  }
  addContactPerson() {
    const newItem = {
      fullName: null,
      phoneNumber: null,
      emailId: null,
    }
    this.contactDetails.push(newItem);
  }

  removeContactPerson(index) {
    this.contactDetails.splice(index, 1);
  }
  onFileChanged(event: Event, DocumentName: string) {
    this.fileUpload(event, DocumentName)
      .then(result => {
        // Handle success
        console.log('File uploaded successfully:', result);
      })
      .catch(error => {
        // Handle error
        console.error('File upload failed:', error);
      });
  }

  fileUpload(event, DocName) {
    return new Promise((resolve, reject) => {
      let fileToUpload = event.target.files.item(0);
      let xhr = this.accountService.uploadDocs(fileToUpload, DocName);

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            let res = JSON.parse(xhr.responseText);
            // Assign the URL to the corresponding DocName key in the docFiles object
            this.docFiles[DocName] = res['url'];
            resolve(this.docFiles);
          } else {
            reject(`Error uploading ${DocName}. Status: ${xhr.status}`);
          }
        }
      };
    });
  }

  getKycData() {
    this.accountService.getKycData().subscribe({
      next: data => {
        if (data && data['status'] === 200) {
          this.kycData = data;
          this.isSaveButtonVisible = false;
          this.businessDetailsForm.disable();
          this.promotersDetailsForm.disable();
          this.setFinancierData(data);
        } else {
          // Handle other statuses or unexpected data format
          console.error('Unexpected response format or status', data);
        }
      },
      error: error => {
        // Proper error handling here
        console.error('An error occurred while fetching KYC data:', error);
        // Optional: Display an error message to the user
      },
      complete: () => {
        // Optional: Any cleanup logic or final steps after the observable completes
      }
    });
  }

  enableForm() {
    this.isSaveButtonVisible = true;
    this.businessDetailsForm.enable();
    this.promotersDetailsForm.enable();
  }

  setFinancierData(data) {
    this.setBusinessDetailsForm(data.result.buyerKyc);
    this.setPromotersForm(data.result.buyerPromoter);
    this.setBuyerContactDetails(data.result.buyerContact);
    this.setDocumentCollectiondetails(data.result.kycDocument);
  }

  setBusinessDetailsForm(businessDetails) {
    businessDetails.dateOfCom = moment(new Date(businessDetails.dateOfCom)).format('yyyy-MM-DD');
    businessDetails.incorporationDate = moment(new Date(businessDetails.incorporationDate)).format('yyyy-MM-DD');
    this.businessDetailsForm.patchValue(businessDetails);
  }

  setPromotersForm(promoterDetails) {
    promoterDetails.associationDate = moment(new Date(promoterDetails.associationDate)).format('yyyy-MM-DD');
    promoterDetails.dob = moment(new Date(promoterDetails.dob)).format('yyyy-MM-DD')
    this.promotersDetailsForm.patchValue(promoterDetails);
  }

  setBuyerContactDetails(contactDetails) {
    this.contactDetails = contactDetails;
  }

  setDocumentCollectiondetails(documentCollection) {
    this.gstReportUrl = documentCollection.gstReport;
    this.gstregistrationUrl = documentCollection.gstRegistration;
    this.partenershipDeadUrl = documentCollection.moa;
    this.panUrl = documentCollection.panDoc;
    this.iecUrl = documentCollection.iecCertificate;
    this.banckStatementUrl = documentCollection.bankStatement;
    this.termLoanUrl = documentCollection.termLoanDetail;
    this.itrReportUrl = documentCollection.itrReport;
    this.balancesheetUrl = documentCollection.auditBS;
  }

  clearStack(event) {
    event.target.value = null;
  }

  downloadFile(url, docName) {
    const link = document.createElement('a');
    link.href = url;
    link.download = docName; // Set a meaningful filename
    link.click();
  }

  kycComp() {
    this.router.navigate(['myaccount']);
  }
  foods: Food[] = [
    { value: 'steak-0', viewValue: 'Steak' },
    { value: 'pizza-1', viewValue: 'Pizza' },
    { value: 'tacos-2', viewValue: 'Tacos' },
  ];
}

export interface BuyerKyc {
  buyerContact: BuyerContact;
  buyerPromoter: BuyerPromoter;
  kycDocument: KycDocument;
}

export interface BuyerContact {
}

export interface BuyerPromoter {
  name: string;
  kycNo: string;
  associationDate: number;
  dob: number;
  gender: string;
  nationality: string;
  residence: string;
  address: string;
  state: string;
  zipCode: number;
  phoneNo: number;
  email: string;
  panNo: string;
}

export interface KycDocument {
  gstReport: string;
  gstRegistration: string;
  moa: string;
  panDoc: string;
  iecCertificate: string;
  bankStatement: string;
  termLoanDetail: string;
  auditBS: string;
  itrReport: string;
}
interface ContactDetails {
  fullName: string | null;
  phoneNumber: string | null;
  emailId: string | null;
}
interface Food {
  value: string;
  viewValue: string;
}
