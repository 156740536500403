import { Injectable, Inject, PLATFORM_ID, signal } from '@angular/core';
import { DataService } from '../data.service';
import { environment } from '../../environments/environment';
import { urls } from '../urls/urls';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { jwtDecode } from 'jwt-decode';
@Injectable({
  providedIn: 'root',
})
export class MyAccountService {
  constructor(
    private dataService: DataService,
    private httpClient: HttpClient
  ) { }

  isbuyerUser() {
    return this.getUserType() == 'BUYER';
  }

  isSupplierUser() {
    return this.getUserType() == 'SUPPLIER';
  }

  getUserType() {
    const decode = jwtDecode(this.dataService.getToken());
    if (decode && decode['role'])
      return decode['role'];
  }

  getUserBankdetails(id?: string) {
    const url = environment.BASEURL + urls.GET_BUYER_ACCOUNT_DETAILS;
    if (id != null) {
      let params = new HttpParams();
      params = params.append('id', id);
      return this.httpClient.get(url, { params: params })
    }
    return this.httpClient.get(url,);
  }

  getUserAccountDetails(id?: string): Observable<any> {
    const url = id ? environment.BASEURL + urls.ACCOUNT_DETILS + `?id=${id}` : environment.BASEURL + urls.ACCOUNT_DETILS;
    return this.httpClient.get(url,);
  }

  addfinancer(obj: any) {
    const url = environment.BASEURL + urls.ADD_FINANCIER;
    return this.dataService.addFinancer(obj,);
  }

  addKycDoc(obj: any) {
    const url = environment.BASEURL + urls.ADD_KYC;
    return this.dataService.addKyc(obj,);
  }

  getKycData() {
    const url = environment.BASEURL + urls.GET_KYC_DATA;
    return this.dataService.getKycData();
  }

  getKycDataWithId(id?: string): Observable<any> {
    return this.dataService.getKycDataWithId(id);
  }
  saveKycDetails(obj) {
    const url = environment.BASEURL + urls.SAVE_KYC_DETAILS;
    return this.dataService.saveKycDetails(obj,);
  }

  uploadDocs(file, docName: string) {
    let url = environment.BASEURL + urls.UPLOAD_DOCUMENT + '?docName=' + docName;
    let uploadData = [];
    uploadData.push(file);
    return this.dataService.callRestfulWithFormData('POST', url, uploadData);
  }

  addBankDetails(obj): any {
    const url = environment.BASEURL + urls.ADD_BANKS_DETAILS;
    return this.httpClient.post(url, obj,);
  }
}
