import { Injectable, Inject, PLATFORM_ID, signal } from '@angular/core';
import { environment } from '../../environments/environment';
import { urls } from '../urls/urls';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { DataService } from '../data.service';
@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(
    private httpClient: HttpClient, private dataService: DataService,
  ) { }

  getDashboardDetails() {
    const url = environment.BASEURL + urls.GET_BUYER_DashBoard_DETAILS;
    return this.httpClient.get(url,);
  }

  getbuyerAccountDetails(): any {
    const url = environment.BASEURL + urls.GET_BUYER_DETAILS;
    return this.httpClient.get(url);
  }

  getSupplierLender(): any {
    const url = environment.BASEURL + urls.GET_SUPPLIER_FINANCIER;
    return this.httpClient.get(url,);
  }

  uploadDocs(file, docName: string) {
    let url = environment.BASEURL + urls.UPLOAD_DOCUMENT + '?docName=' + docName;
    let uploadData = [];
    uploadData.push(file);
    return this.dataService.callRestfulWithFormData('POST', url, uploadData);
  }

  getFinancierDocsDetails() {
    const url = environment.BASEURL + urls.GET_FINCIER_DOCS;
    return this.httpClient.get(url,);
  }

  getBuyerList(lenderID) {
    const url = environment.BASEURL + urls.GET_BUYER_LIST + '?lenderId=' + lenderID;
    return this.httpClient.get(url,);
  }

  getUserAccountDetails(): any {
    const url = environment.BASEURL + urls.ACCOUNT_DETILS;
    return this.httpClient.get(url,);
  }
  applyFinanceReq(obj) {
    let url = environment.BASEURL + urls.setFinanceReq + `?limitRequired=${obj.limitRequired}` + `&lenderId=${obj.lenderId}` + `&supplierId=${obj.supplierId}`;
    return this.httpClient.post(url, null,);
  }
  isKycVerified() {
    return this.dataService.isKycVerified()
  }

  approveSanction(obj) {
    const url = environment.BASEURL + urls.APPROVE_SANCTION;
    return this.httpClient.post(url, obj,);
  }
  initiateDisbursement(obj) {
    let url = environment.BASEURL + urls.initiateDisbursement;
    return this.httpClient.post(url, obj)
  }

  getFinancers(lenderId) {
    const url = environment.BASEURL + urls.GET_FINANCIER + '?lenderId=' + lenderId;
    return this.httpClient.get(url,);
  }

  getInterestRecord(obj) {
    let url = environment.BASEURL + urls.GET_INTEREST_RATE;
    return this.httpClient.post(url, obj)
  }
}
