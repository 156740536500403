<ng-container class="buyerLoanContainer">
  <div class="popupContent">
    <div class="loanheader">
      <h2 class="loanHeader">Financing Request</h2>
      <mat-icon (click)="closePopup()">close</mat-icon>
    </div>
    <div class="warnMsg">
      <p>Before applying for loan kindly provide few details to help us provide you the better credit limit.</p>
    </div>

    <div class="limitRequirment">
      <mat-form-field appearance="outline">
        <mat-label>Limit Requirement(in Cr)</mat-label>
        <input matInput type="number" [(ngModel)]="limitRequired" />
      </mat-form-field>
      <div class="btnDiv">
        <button mat-button class="cancelBtn" color="primary" (click)="closePopup()"> cancel</button>
        <button mat-button class="applyBtn" color="warn" [disabled]="checkDocs" (click)="applyFinancingRequest()">
          Apply</button>
      </div>
    </div>

    <div class="reqDocs">
      <p class="secName">Required Document</p>
      <div>
        <section>
          <div class="v-kyc-row clearfix">
            <div class="f-field">
              <label [ngStyle]="!isSaved && {'color': '#241f1f'}"><strong>GST Report</strong></label>
              <button mat-raised-button (click)="downloadFile('gstReport')" class="Download-button">
                <mat-icon>download</mat-icon>
                <span>Download</span>
              </button>
              <!-- @if(!gstReportName){
              <input type="file" #gstInput id="gstInput" class="form-control" placeholder="GST Report" [(ngModel)]="pan"
                [multiple]="false" (click)="clearStack($event)" (change)="onFileChanged($event, 'GST Report')">
              @if(!gstReport){
              <mat-icon class="uploadIcon">cloud_upload</mat-icon>
              }@else{
              <mat-icon class="uploadIcon">close</mat-icon>
              }
              }
              @else{
              <mat-form-field appearance="outline">
                <mat-label>GST Report</mat-label>
                <input matInput [(ngModel)]="gstReportName" readonly="true" (click)="downloadFile('gstReport')" />
                <mat-icon>cloud_download</mat-icon>
              </mat-form-field> -->
              <!-- } -->
            </div>

            <div class="f-field">
              <label [ngStyle]="!isSaved && {'color': '#241f1f'}"><strong>GST Registration</strong></label>
              <button mat-raised-button (click)="downloadFile('gstRegistration')" class="Download-button">
                <mat-icon>download</mat-icon>
                <span>Download</span>
              </button>
            </div>

            <div class="f-field">
              <label [ngStyle]="!isSaved && {'color': '#241f1f'}"><strong>MOA /AOA/ Partenership</strong></label>
              <button mat-raised-button (click)="downloadFile('moa')" class="Download-button">
                <mat-icon>download</mat-icon>
                <span>Download</span>
              </button>

            </div>
          </div>

          <div class="v-kyc-row clearfix">
            <div class="f-field">
              <label [ngStyle]="!isSaved && {'color': '#241f1f'}"><strong>PAN</strong></label>
              <button mat-raised-button (click)="downloadFile('panDoc')" class="Download-button">
                <mat-icon>download</mat-icon>
                <span>Download</span>
              </button>
            </div>

            <div class="f-field">
              <label [ngStyle]="!isSaved && {'color': '#241f1f'}"><strong>IEC Certificate</strong></label>
              <button mat-raised-button (click)="downloadFile('iecCertificate')" class="Download-button">
                <mat-icon>download</mat-icon>
                <span>Download</span>
              </button>
            </div>

            <div class="f-field">
              <label [ngStyle]="!isSaved && {'color': '#241f1f'}"><strong>3 Month Bank Statement</strong></label>
              <button mat-raised-button (click)="downloadFile('bankStatement')" class="Download-button">
                <mat-icon>download</mat-icon>
                <span>Download</span>
              </button>
            </div>
          </div>

          <div class="v-kyc-row clearfix">
            <div class="f-field">
              <label [ngStyle]="!isSaved && {'color': '#241f1f'}"><strong>Detail of Term Loan</strong></label>
              <button mat-raised-button (click)="downloadFile('termLoanDetail')" class="Download-button">
                <mat-icon>download</mat-icon>
                <span>Download</span>
              </button>
            </div>

            <div class="f-field">
              <label [ngStyle]="!isSaved && {'color': '#241f1f'}"><strong>Audited Balance Sheet</strong></label>
              <button mat-raised-button (click)="downloadFile('auditBS')" class="Download-button">
                <mat-icon>download</mat-icon>
                <span>Download</span>
              </button>
            </div>
            <div class="f-field itrclass">
              <label [ngStyle]="!isSaved && {'color': '#241f1f'}"><strong>Borrowers ITR Report</strong></label>
              <button mat-raised-button (click)="downloadFile('itrReport')" class="Download-button">
                <mat-icon>download</mat-icon>
                <span>Download</span>
              </button>
            </div>

          </div>
        </section>
      </div>
    </div>
    <div class="warnDiv">
      <mat-checkbox class="example-margin" [(ngModel)]="agreeTerms">
        <p class="termsCondition">
          I agree to share my documents with the lender as part of the financing request
        </p>
      </mat-checkbox>
    </div>
  </div>
</ng-container>