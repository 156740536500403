<app-loader *ngIf="loadingService.getLoadingState()"></app-loader>
<app-toast-container *ngIf="toasterService.getToasterState()"></app-toast-container>
<ng-container>
  <div class="form-container">
    <div class="form-header">
      <i class="ri-bar-chart-box-line icon-color"></i>
      <span>Add Customer Details</span>
    </div>

    <form [formGroup]="customerForm" (ngSubmit)="onSubmit()">
      <div class="form-content">
        <!-- Basic Details Section -->
        <div class="inner-container">
          <div class="section-header">Basic Details</div>

          <!-- Company Name Field -->
          <mat-form-field appearance="outline">
            <mat-label>Company Name</mat-label>
            <input matInput #companyName placeholder="Ex. Mogli Labs Pvt Ltd" maxlength="32" type="text"
              formControlName="companyName">
            <mat-hint align="end">{{companyName.value.length}} / 32</mat-hint>
            <!-- <mat-error *ngIf="companyDetails.get('companyName').hasError('required')">
              Company Name is <strong>required</strong>
            </mat-error> -->
          </mat-form-field>

          <!-- Display Name Field -->
          <mat-form-field appearance="outline">
            <mat-label>Phone Number</mat-label>
            <input matInput #displayName maxlength="10" placeholder="Ex. 9876543210" type="text"
              formControlName="phoneNumber" pattern="[0-9]*">
            <mat-hint align="end">{{displayName.value.length}} / 10</mat-hint>
            <!-- <mat-error *ngIf="companyDetails.get('displayName').hasError('required')">
              Phone Number is <strong>required</strong>
            </mat-error> -->
          </mat-form-field>

          <!-- GSTIN Field -->
          <mat-form-field appearance="outline">
            <mat-label>GSTIN</mat-label>
            <input matInput #gstIn maxlength="15" placeholder="Ex. 22AAAAA0000A1Z5" type="text" formControlName="gstIn">
            <mat-hint align="end">{{gstIn.value.length}} / 15</mat-hint>
            <!-- <mat-error *ngIf="companyDetails.get('gstIn').hasError('required')">
              GstIn <strong>required</strong>
            </mat-error> -->
          </mat-form-field>

          <!-- Login Email ID Field -->
          <mat-form-field appearance="outline">
            <mat-label>Login Email ID</mat-label>
            <mat-icon matSuffix>account_box</mat-icon>
            <input matInput placeholder="Ex. xyz@gmail.com" type="email" formControlName="emailId">

            <!-- <mat-error
              *ngIf="companyDetails.get('loginEmailId').hasError('email') && !companyDetails.get('loginEmailId').hasError('required')">
              Please enter a valid email address
            </mat-error>
            <mat-error *ngIf="companyDetails.get('loginEmailId').hasError('required')">
              Email is <strong>required</strong>
            </mat-error> -->
          </mat-form-field>
        </div>

      </div>
      <div class="form-container">
        <button type="submit" class="submit-btn">Submit</button>
      </div>
    </form>
  </div>
</ng-container>