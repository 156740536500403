import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddBankComponent } from '../../my-account/basic-details/add-bank/add-bank.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { AuthService } from '../../auth/auth.service';
import { MyAccountService } from '../../my-account/myAccount.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../services/toast.service';
import { LoadingService } from '../../services/loader.service';

@Component({
  selector: 'app-customer-detail',
  template: '<p>Component with the ID: {{userId}}</p>',
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    MatTabsModule,
    MatButtonModule,
    MatTableModule,
    MatIconModule
  ],
  providers: [MyAccountService, AuthService, LoadingService],
  templateUrl: './customer-detail.component.html',
  styleUrl: './customer-detail.component.scss'
})
export class CustomerDetailComponent {
  @Input() public hideUsersInfo: any;
  isCustomerKycVerified: any;
  dataSource: any = [];
  displayedColumns: any = ['accNumber', 'bankName', 'branchName', 'ifscCode'];
  userDetail: any = {};


  constructor(
    private router: Router,
    private myAccountService: MyAccountService,
    public dialog: MatDialog,
    private authService: AuthService,
    private route: ActivatedRoute,
    private toasterService: ToastService,
    private loadingService: LoadingService,
  ) { }

  basicDetailShouldDisplay: boolean = true;
  isCustomerKycDone: boolean = false;
  userId: string;

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.userId = params['id'];
    });
    if (this.userId) {
      this.getAccountDetails(this.userId);
      this.getBankDetails(this.userId);
    }
  }

  kycComp() {
    this.router.navigate([`/kyc-doc/${this.userId}`]);
  }
  getAccountDetails(userId: string) {
    //
    this.loadingService.setLoadingState(true);
    this.myAccountService.getUserAccountDetails(userId).subscribe({
      next: (res) => {
        if (res && res['status'] === 200) {
          this.userDetail = res.result;

        }
        else {
          this.toasterService.show('Error', res['message']);
          this.loadingService.setLoadingState(false);
        }
      },
      error: (error) => {
        console.error('Error fetching user account details', error);
        this.userDetail = [];
        this.loadingService.setLoadingState(false);
      },
      complete: () => {
        this.loadingService.setLoadingState(false);
      }
    });
  }


  getBankDetails(userid: string) {

    this.myAccountService.getUserBankdetails(userid).subscribe({
      next: (data) => {
        if (data && data['status'] == 200 && data['result']) {
          this.dataSource = data['result'];

        }
        else {
          this.toasterService.show('Error', data['message']);

        }
      },
      error: (error) => {
        console.error('Error fetching bank details', error);

        this.dataSource = [];
      }
    });
  }


  addBank() {
    const dialogRef = this.dialog.open(AddBankComponent, {
      width: "1050px",
      height: "300px",
      data: {}
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getBankDetails(this.userId);
    });
  }
  isBuyerUser() {
    return this.authService.isbuyerUser();
  }
  isSupplieruser() {
    return this.authService.isSupplierUser();
  }
}
