<div class="account-tabs-sec" #activeKycTabOnKycDone>
  <ul class="nav nav-tabs" role="tablist">
    <li role="presentation" class="nav-item" [ngClass]="{'active': basicDetailShouldDisplay}" (click)="kycComp()">
      <a class="nav-link" aria-controls="basicDetails" role="tab" data-toggle="tab">
        <i class="ri-user-line"></i> Basic Details
      </a>
    </li>
    <li class="nav-item" [ngClass]="{'active': isCustomerKycDone}">
      <a class="nav-link" aria-controls="kycVerification" role="tab" data-toggle="tab">
        <i class="ri-user-follow-line"></i> KYC for Financing
      </a>
    </li>
  </ul>
</div>

<div class="main-div">
  <div role="tabpanel" class="tab-pane" id="kycVerification">
    <section id="kyc-Details">
      <div class="v-tab-inner v-tab-plant v-bank-info">
        <form [formGroup]="businessDetailsForm">
          <div class="v-tab-heading clearfix">
            <h3>KYC for Financing </h3>
            <!-- <button class="savebtn" mat-button color="primary" (click)="submitKycDoc()">SAVE</button> -->

          </div>

          <div class="kyc-detail">
            <h3>Business Details</h3>

            <div class="v-kyc-row clearfix">
              <div class="f-field">
                <input type="text" id="companyName" class="form-control" formControlName="companyName"
                  style="background-color: rgb(231, 231, 231)">
                <label>Company Name /Entity
                  Name</label>
              </div>

              <div class="f-field">
                <input type="date" id="dateOfIncorporation" class="form-control"
                  placehreadonlyolder="Date of Incorporation" formControlName="incorporationDate">
                <label [ngStyle]="!isSaved && {'color': '#241f1f'}">Date of
                  Incorporation</label>
              </div>

              <div class="f-field">
                <input type="date" id="dateOfAssociation" class="form-control"
                  placehreadonlyolder="Date of Commencement of Bussiness" formControlName="dateOfCom">
                <label [ngStyle]="!isSaved && {'color': '#241f1f'}">Date of
                  Commencement of Bussiness</label>
              </div>
            </div>

            <div class="v-kyc-row clearfix">
              <div class="f-field">
                <input type="text" id="businessNature" class="form-control" formControlName="businessNature"
                  style="background-color: rgb(231, 231, 231)">
                <label>Nature of Business</label>
              </div>

              <div class="f-field">
                <input type="text" id="companyName" class="form-control" formControlName="panNo"
                  style="background-color: rgb(231, 231, 231)" [ngStyle]="!isSaved && {'color': '#363636'}">
                <label [ngStyle]="!isSaved && {'color': '#241f1f'}">PAN Number</label>
              </div>

              <div class="f-field">
                <input type="text" id="companyName" class="form-control" formControlName="gstin"
                  style="background-color: rgb(231, 231, 231)">
                <label>GSTIN</label>
              </div>

            </div>
            <div class="v-kyc-row clearfix">
              <div class="f-field">
                <input type="text" class="form-control" formControlName="address">
                <label>Company Address</label>
              </div>

              <div class="f-field">
                <input type="text" class="form-control" formControlName="state">
                <label>State</label>
              </div>

              <div class="f-field">
                <input type="text" class="form-control" formControlName="zipCode">
                <label>Zip Code</label>
              </div>
            </div>
            <div class="v-kyc-row clearfix">
              <div class="f-field">
                <input type="text" id="companyName" class="form-control" formControlName="businessInvolved"
                  style="background-color: rgb(231, 231, 231)">
                <label>Business Involved In</label>
              </div>

              <div class="f-field">
                <input type="text" id="dateOfIncorporation" class="form-control" formControlName="iecNumber">
                <label>IEC Number</label>
              </div>

              <div class="f-field">
                <input type="text" class="form-control" formControlName="vinatageInBusiness"
                  [ngStyle]="!isSaved && {'background-color': '#F8F0B2'}">
                <label [ngStyle]="!isSaved && {'color': '#241f1f'}">Vintage in Business</label>
              </div>

            </div>
            <div class="v-kyc-row clearfix">
              <div class="f-field">
                <input type="text" class="form-control" formControlName="turnOver" placeholder="in Cr"
                  [ngStyle]="!isSaved && {'background-color': '#F8F0B2'}">
                <label [ngStyle]="!isSaved && {'color': '#241f1f'}">Turnover</label>
              </div>
            </div>
          </div>
        </form>
        <div class="kyc-detail">
          <div class="addmore">
            <h3>Contact Person(s)</h3>
            <button mat-button color="primary" class="v-btn add-more" type="button" (click)="addContactPerson()">
              <mat-icon>add_circle</mat-icon><span> Add More </span>
            </button>
          </div>
          <div *ngFor="let item of contactDetails; let i = index" class="v-kyc-row clearfix">

            <div class="f-field">
              <input type="text" class="form-control" [(ngModel)]="item.fullName"
                [ngStyle]="!isSaved && {'background-color': '#F8F0B2'}">
              <label [ngStyle]="!isSaved && {'color': '#241f1f'}">Full Name</label>
            </div>

            <div class="f-field phone"><span class="countrycode">+91</span>
              <input type="text" class="form-control" [(ngModel)]="item.phoneNumber" maxlength="10"
                style="padding-left: 45px;" [ngStyle]="!isSaved && {'background-color': '#F8F0B2'}">
              <label [ngStyle]="!isSaved && {'color': '#241f1f'}" for="phone" class="">Phone
                No.<span>*</span></label>
              <!-- <span class="error-text">Phone No. is required.</span> -->
            </div>

            <div class="f-field">
              <input type="email" class="form-control" [(ngModel)]="item.emailId"
                [ngStyle]="!isSaved && {'background-color': '#F8F0B2'}">
              <label [ngStyle]="!isSaved && {'color': '#241f1f'}" for="emailid" class="label-active">Email</label>
            </div>

            @if(contactDetails.length > 1){
            <button mat-button color="warn" class="v-btn removeContact" type="button" (click)="removeContactPerson(i)">
              <mat-icon>remove_circle</mat-icon><span> Remove </span>
            </button>
            }
          </div>
        </div>

        <div class="kyc-detail">

          <form [formGroup]="promotersDetailsForm">

            <div class="heading" style="margin: 20px 3px;">
              <h3>Promoter(s) Details</h3>

              <button *ngIf="!isSaved" class="v-btn add-more" (click)="addPromoters()">
                <i class="ri-add-line"></i><span> Add Promoter </span>
              </button>

              <button style="color:red;" *ngIf="!isSaved" class="v-btn add-more" type="button"
                (click)="removePromoters()">
                <i class="ri-delete-bin-2-line"></i><span> Remove </span>
              </button>
            </div>

            <div class="v-kyc-row clearfix">
              <div class="f-field">
                <input type="text" class="form-control" formControlName="promoterName"
                  [ngStyle]="!isSaved && {'background-color': '#F8F0B2'}">
                <label [ngStyle]="!isSaved && {'color': '#241f1f'}">Name</label>
              </div>

              <div class="f-field">
                <input type="text" class="form-control" formControlName="kycNumber"
                  [ngStyle]="!isSaved && {'background-color': '#F8F0B2'}">
                <label [ngStyle]="!isSaved && {'color': '#241f1f'}">KYC Number (if
                  available) </label>
              </div>

              <div class="f-field">
                <input type="date" id="dateOfAssociation" class="form-control" placeholder="Date of Association"
                  formControlName="associationDate" [ngStyle]="!isSaved && {'background-color': '#F8F0B2'}">
                <label [ngStyle]="!isSaved && {'color': '#241f1f'}">Date of
                  Association</label>
              </div>


              <div class="f-field">
                <input type="date" id="dateOfBirth" class="form-control" placeholder="Date of Birth"
                  formControlName="dob" [ngStyle]="!isSaved && {'background-color': '#F8F0B2'}">
                <label [ngStyle]="!isSaved && {'color': '#241f1f'}">Date of
                  Birth</label>

              </div>
            </div>

            <div class="v-kyc-row clearfix">
              <div class="f-field">
                <select class="form-control" formControlName="gender"
                  [ngStyle]="!isSaved && {'background-color': '#F8F0B2','cursor':'pointer'}">
                  <option value='Male'>Male</option>
                  <option value='Female'>Female</option>
                  <option value='Other'>Other</option>
                </select>
                <!-- <input [hidden]="!isSaved" type="text" class="form-control" formControlName="gender"> -->
                <label [ngStyle]="!isSaved && {'color': '#241f1f'}">Gender</label>
              </div>


              <div class="f-field">
                <ng-container>
                  <select class="form-control" id="nationality" formControlName="nationality"
                    [ngStyle]="!isSaved && {'background-color': '#F8F0B2','cursor':'pointer'}">
                    <option value='Indian'>Indian</option>
                    <option value='Other'>Other</option>
                  </select>
                  <!-- <input *ngIf="isSaved" type="text" class="form-control" formControlName="nationality"> -->
                </ng-container>
                <label [ngStyle]="!isSaved && {'color': '#241f1f'}">Nationality</label>
              </div>

              <div class="f-field">
                <ng-container>
                  <select class="form-control" id="residentialStatus" formControlName="residence"
                    [ngStyle]="!isSaved && {'background-color': '#F8F0B2','cursor':'pointer'}">
                    <option value='Indian'>Indian</option>
                    <option value='Other'>Other</option>
                  </select>
                  <!-- <input *ngIf="isSaved" type="text" class="form-control" formControlName="residentialStatus"> -->
                </ng-container>
                <label [ngStyle]="!isSaved && {'color': '#241f1f'}">Residential
                  Status</label>
              </div>

              <div class="f-field">
                <input type="text" class="form-control" placeholder="Type Your Address" formControlName="address"
                  [ngStyle]="!isSaved && {'background-color': '#F8F0B2'}">
                <label [ngStyle]="!isSaved && {'color': '#241f1f'}">Address</label>
              </div>

            </div>

            <div class="v-kyc-row clearfix">

              <div class="f-field">
                <ng-container>
                  <select class="form-control" id="residentialStatus" formControlName="state"
                    [ngStyle]="!isSaved && {'background-color': '#F8F0B2','cursor':'pointer'}">
                    <option value=''>Select State</option>
                    <option value='Indian'>UP</option>
                    <option value='Other'>MP</option>
                  </select>
                  <!-- <input *ngIf="isSaved" type="text" class="form-control" formControlName="residentialStatus"> -->
                </ng-container>
                <label [ngStyle]="!isSaved && {'color': '#241f1f'}">State</label>
              </div>


              <div class="f-field">
                <input type="text" class="form-control" maxlength="6" placeholder="Type Your Zip code"
                  formControlName="zipCode" [ngStyle]="!isSaved && {'background-color': '#F8F0B2'}">
                <label [ngStyle]="!isSaved && {'color': '#241f1f'}" for="pinCode">Zip code</label>
              </div>

              <div class="f-field phone"><span class="countrycode">+91</span>
                <input type="text" id="phoneNumber" formControlName="phoneNo" maxlength="10" pattern=[0-9]{10}
                  tabindex="3" class="form-control" style="padding-left: 45px;"
                  [ngStyle]="!isSaved && {'background-color': '#F8F0B2'}">
                <label [ngStyle]="!isSaved && {'color': '#241f1f'}" for="phoneNumber" class="">Phone
                  No.<span>*</span></label>
                <!-- <span class="error-text">Phone No. is required.</span> -->
              </div>

              <div class="f-field">
                <input type="email" formControlName="email" class="form-control"
                  [ngStyle]="!isSaved && {'background-color': '#F8F0B2'}">
                <label [ngStyle]="!isSaved && {'color': '#241f1f'}" for="emailId" class="label-active">Email</label>
                <!-- <span class="error-text">Please enter your email address</span> -->
              </div>
            </div>
          </form>
        </div>
        <div class="documentCollection">
          <div class="kyc-detail">

            <div class="heading" style="margin: 20px 3px;">
              <h3>Document Collection</h3>
            </div>
            <section>
              <div class="v-kyc-row clearfix">
                <div class="f-field">
                  @if(!gstReportUrl){
                  <input type="file" class="form-control" [(ngModel)]="gstReport" placeholder="GST Report"
                    [multiple]="false" (click)="clearStack($event)" (change)="onFileChanged($event, 'gstReport')"
                    [ngStyle]="!isSaved && {'background-color': '#F8F0B2'}">
                  <label [ngStyle]="!isSaved && {'color': '#241f1f'}">GST Report</label>
                  }@else{
                  <div class="download-input-container">
                    <span (click)="downloadFile(gstReportUrl, 'gstReport')">
                      <input type="text" placeholder="GST Report" class="input-with-icon">
                      <i class="material-icons download-icon">file_download</i>
                    </span>
                  </div>
                  }
                </div>

                <div class="f-field">
                  @if(!gstregistrationUrl){
                  <input type="file" class="form-control" [(ngModel)]="gstregistration" placeholder="GST Registration"
                    [ngStyle]="!isSaved && {'background-color': '#F8F0B2'}" [multiple]="false"
                    (click)="clearStack($event)" (change)="onFileChanged($event, 'gstRegistration')">
                  <label [ngStyle]="!isSaved && {'color': '#241f1f'}">GST Registration</label>
                  }@else{
                  <div class="download-input-container">
                    <span (click)="downloadFile(gstregistrationUrl, 'gstregistration')">
                      <input type="text" placeholder="GST Registration" class="input-with-icon">
                      <i class="material-icons download-icon">file_download</i>
                    </span>
                  </div>

                  }
                </div>

                <div class="f-field">
                  @if(!partenershipDeadUrl){
                  <input type="file" id="dateOfAssociation" class="form-control" placeholder="MOA /AOA/ Partenership"
                    [(ngModel)]="partenershipDead" [ngStyle]="!isSaved && {'background-color': '#F8F0B2'}"
                    [multiple]="false" (click)="clearStack($event)" (change)="onFileChanged($event, 'moa')">
                  <label [ngStyle]="!isSaved && {'color': '#241f1f'}">MOA /AOA/ Partenership</label>
                  }@else{
                  <div class="download-input-container">
                    <span (click)="downloadFile(partenershipDeadUrl, 'partenershipDead')">
                      <input type="text" placeholder="MOA /AOA/ Partenership" class="input-with-icon">
                      <i class="material-icons download-icon">file_download</i>
                    </span>
                  </div>
                  }
                </div>

                <div class="f-field">
                  @if(!panUrl){
                  <input type="file" id="dateOfBirth" class="form-control" placeholder="PAN" [(ngModel)]="pan"
                    [ngStyle]="!isSaved && {'background-color': '#F8F0B2'}" [multiple]="false"
                    (click)="clearStack($event)" (change)="onFileChanged($event, 'panDoc')">
                  <label [ngStyle]="!isSaved && {'color': '#241f1f'}">PAN</label>
                  }@else{
                  <div class="download-input-container">
                    <span (click)="downloadFile(panUrl, 'panDoc')">
                      <input type="text" placeholder="PAN" class="input-with-icon">
                      <i class="material-icons download-icon">file_download</i>
                    </span>
                  </div>
                  }
                </div>
              </div>

              <div class="v-kyc-row clearfix">
                <div class="f-field">
                  @if(!iecUrl){
                  <input type="file" class="form-control" placeholder="IEC Certificate" [(ngModel)]="iec"
                    [ngStyle]="!isSaved && {'background-color': '#F8F0B2'}" [multiple]="false"
                    (click)="clearStack($event)" (change)="onFileChanged($event, 'iecCertificate')">
                  <label [ngStyle]="!isSaved && {'color': '#241f1f'}">IEC Certificate</label>
                  }@else{
                  <div class="download-input-container">
                    <span (click)="downloadFile(iecUrl, 'iecCertificate')">
                      <input type="text" placeholder="IEC Certificate" class="input-with-icon">
                      <i class="material-icons download-icon">file_download</i>
                    </span>
                  </div>
                  }
                </div>

                <div class="f-field">
                  @if(!banckStatementUrl){
                  <input type="file" class="form-control" placeholder="Last 3 Month Banck statement"
                    [(ngModel)]="banckStatement" [ngStyle]="!isSaved && {'background-color': '#F8F0B2'}"
                    [multiple]="false" (click)="clearStack($event)" (change)="onFileChanged($event, 'bankStatement')">
                  <label [ngStyle]="!isSaved && {'color': '#241f1f'}">Last 3 Month Banck Statement</label>
                  }@else{
                  <div class="download-input-container">
                    <span (click)="downloadFile(banckStatementUrl, 'banckStatement')">
                      <input type="text" placeholder="Last 3 Month Banck statement" class="input-with-icon">
                      <i class="material-icons download-icon">file_download</i>
                    </span>
                  </div>
                  }
                </div>

                <div class="f-field">
                  @if(!termLoanUrl){
                  <input type="file" class="form-control" placeholder="Detail of Term Loan" [(ngModel)]="termLoan"
                    [ngStyle]="!isSaved && {'background-color': '#F8F0B2'}" [multiple]="false"
                    (click)="clearStack($event)" (change)="onFileChanged($event, 'termLoanDetail')">
                  <label [ngStyle]="!isSaved && {'color': '#241f1f'}">Detail of Term Loan</label>
                  }@else{
                  <div class="download-input-container">
                    <span (click)="downloadFile(termLoanUrl, 'termLoan')">
                      <input type="text" placeholder="Detail of Term Loan" class="input-with-icon">
                      <i class="material-icons download-icon">file_download</i>
                    </span>
                  </div>
                  }
                </div>

                <div class="f-field">
                  @if(!balancesheetUrl){
                  <input type="file" class="form-control" placeholder="Last 3 year Audited Balance Sheet"
                    [(ngModel)]="balancesheet" [ngStyle]="!isSaved && {'background-color': '#F8F0B2'}"
                    placeholder="Last 3 year Audited Balance Sheet" [multiple]="false" (click)="clearStack($event)"
                    (change)="onFileChanged($event, 'auditBS')">
                  <label [ngStyle]="!isSaved && {'color': '#241f1f'}">Last 3 year Audited Balance Sheet</label>
                  }@else{
                  <div class="input-download-container">
                    <input type="text" placeholder="Last 3 year Audited Balance Sheet" class="input-with-icon">
                    <span class="download-icon" (click)="downloadFile(balancesheetUrl, 'auditBS')">
                      <i class="material-icons">file_download</i>
                    </span>
                  </div>
                  }
                </div>
              </div>

              <div class="v-kyc-row clearfix">
                <div class="f-field itrclass">
                  @if(!itrReportUrl){
                  <input type="file" class="form-control" placeholder="Upload ITR Report for Borrowers"
                    [(ngModel)]="itrReport" [ngStyle]="!isSaved && {'background-color': '#F8F0B2'}"
                    placeholder="Upload ITR Report for Borrowers" [multiple]="false" (click)="clearStack($event)"
                    (change)="onFileChanged($event, 'itrReport')">
                  <label [ngStyle]="!isSaved && {'color': '#241f1f'}">Upload ITR Report for Borrowers</label>
                  }@else{
                  <div class="download-input-container">
                    <span (click)="downloadFile(itrReportUrl, 'itrReport')">
                      <input type="text" placeholder="Upload ITR Report for Borrowers" class="input-with-icon">
                      <i class="material-icons download-icon">file_download</i>
                    </span>
                  </div>
                  }
                </div>
              </div>
            </section>
          </div>
        </div>