<section id="financiaer-Details">
  <div class="v-financier-inner">
    <form [formGroup]="financierForm">
      <div class="v-financier-heading clearfix">
        <div class="headersSec">
          <span>
            <!-- <h3 class="headerText">Financier Details</h3> -->
          </span>
          <span>
            <mat-icon (click)="closeDailog()">close</mat-icon>
          </span>
        </div>
        <!-- <ng-container>
          <h3>Update Financier Details</h3>
        </ng-container> -->
      </div>
      <div class="v-tab-formarea clearfix">
        <div class="financier-detail">
          <h3>Financier Details </h3>
          <div class="v-finacier-row clearfix">
            <div class="f-field">
              <input type="text" class="form-control" id="Financier name" formControlName="financierName"
                placeholder="Enter Financier name" required>
              <label> Financier name <em class="required">*</em></label>
            </div>

            <div class="f-field">
              <select class="form-control" formControlName="type" required>
                <!-- <option [value]="'Select'">Select</option> -->
                <option *ngFor="let financierType of financierTypes" [value]='financierType'>{{financierType}}</option>
              </select>
              <label>Financier Type <em class="required">*</em> </label>
            </div>


            <div class="f-field">
              <input type="text" class="form-control" formControlName="mou" placeholder="Enter MOU" required>
              <label>Mou <em class="required">*</em></label>
            </div>

            <div class="f-field">
              <input type="email" class="form-control" formControlName="financierEmail"
                [readonly]="isFinancierLenderPresent" placeholder="Enter Financier Email" required>
              <label>Financier Email <em class="required">*</em></label>
            </div>

            <div class="f-field">
              <div class="phone-box d-flex">
                <div class="left">
                  <select class="form-control" [ngClass]="{'select-option-disabled':isFinancierLenderPresent}" required>
                    <option value="+91">+91</option>
                  </select>
                  <label>Country Code </label>
                </div>
                <div class="right">
                  <input type="number" class="form-control" formControlName="contactNo"
                    [readonly]="isFinancierLenderPresent" placeholder="Enter Financier Phone" maxlength="10"
                    [ngClass]="{'select-option-disabled':isFinancierLenderPresent}" required>
                  <label>Financier Mobile <em class="required">*</em></label>
                </div>
              </div>
            </div>

            <div class="f-field">
              <input type="number" class="form-control" formControlName="interestRate"
                [readonly]="isFinancierLenderPresent" placeholder="Enter Interest Rate" required>
              <label>Interest Rate(%)<em class="required">*</em></label>
            </div>

            <div class="f-field">
              <input type="text" class="form-control" formControlName="panNo" [readonly]="isFinancierLenderPresent"
                placeholder="Enter PAN No" maxlength="10" required>
              <label>PAN No. <em class="required">*</em></label>
            </div>

            <div class="f-field">
              <input type="text" class="form-control" formControlName="cin" [readonly]="isFinancierLenderPresent"
                placeholder="Enter CIN" maxlength="21">
              <label>CIN<em class="required">*</em></label>
            </div>

            <div class="f-field">
              <input type="number" class="form-control" formControlName="limit" [readonly]="isFinancierLenderPresent"
                placeholder="Enter MOU Limit" required>
              <label>MOU Limit<em class="required">*</em></label>
            </div>

            <div class="f-field">
              <input type="text" class="form-control" [readonly]="isFinancierLenderPresent"
                placeholder="Enter Disbursement Redirected URL">
              <label>Disbursement Redirected URL</label>
            </div>

            <div class="f-field">
              <select class="form-control" [ngClass]="{'select-option-disabled':isFinancierLenderPresent}">
                <option [value]="'Select'">Select</option>
                <option *ngFor="let choose of chooses" [value]='choose' [disabled]=isFinancierLenderPresent>{{choose}}
                </option>
              </select>
              <label> Listed Through Aggregator</label>
            </div>

            <div class="f-field width50">
              <textarea placeholder="Enter Long Description here..." class="form-control" required maxlength="50"
                formControlName="longDescription"></textarea>
              <label>Long Description <em class="required">*</em></label>
            </div>

            <div class="f-field">
              <select class="form-control" [ngClass]="{'select-option-disabled':isFinancierLenderPresent}">
                <option *ngFor="let mode of modes" [value]="mode">
                  {{mode}}
                </option>
              </select>
              <label> Mode of Communication <em class="required">*</em></label>
            </div>
          </div>
        </div>

        <div class="financier-detail new">
          <h3>Contact Person Details</h3>
          <!-- <button class="addmore" (click)="addContactPerson()">
            <i class="ri-add-box-fill"></i>
          </button> -->
          <form [formGroup]="contactPersonForm">
            <div class="financier-inner">
              <div class="v-finacier-row clearfix">
                <!-- <input type="text" class="form-control" formControlName="lenderId">
                <input type="text" class="form-control" formControlName="contactPersonIsPresent"> -->
                <div class="f-field">
                  <select class="form-control">
                    <!-- <option [value]="'Select'" [disabled]=true>Select</option> -->
                    <option *ngFor="let title of titles" [value]='title' [disabled]=isContactPersonPresent>{{title}}
                    </option>
                  </select>
                  <label>Title</label>
                </div>
                <div class="f-field">
                  <input type="text" class="form-control" formControlName="fullName" [readonly]="isContactPersonPresent"
                    placeholder="Enter Contact Person First Name">
                  <label>Contact Person First Name <em class="required">*</em></label>
                </div>
                <div class="f-field">
                  <input type="text" class="form-control" [readonly]="isContactPersonPresent"
                    placeholder="Enter Contact Person Middle Name">
                  <label>Contact Person Middle Name</label>
                </div>

                <div class="f-field">
                  <input type="text" class="form-control" [readonly]="isContactPersonPresent"
                    placeholder="Enter Contact Person Last Name" required>
                  <label>Contact Person Last Name <em class="required">*</em></label>
                </div>

                <div class="f-field">
                  <input type="text" class="form-control" formControlName="designation"
                    [readonly]="isContactPersonPresent" placeholder="Enter Contact Person Designation" required>
                  <label>Contact Person Designation <em class="required">*</em></label>
                </div>

                <div class="f-field">
                  <input type="email" class="form-control" formControlName="emailId" [readonly]="isContactPersonPresent"
                    placeholder="Enter Contact Person Email" required>
                  <label>Contact Person Email <em class="required">*</em></label>
                </div>

                <div class="f-field">
                  <div class="phone-box d-flex">
                    <div class="left">
                      <select class="form-control" disabled
                        [ngClass]="{'select-option-disabled':isContactPersonPresent}">
                        <option value="+91">+91</option>
                      </select>
                      <label>Country Code </label>
                    </div>
                    <div class="right">
                      <input type="number" class="form-control" formControlName="phoneNo"
                        [readonly]="isContactPersonPresent" placeholder="Enter Contact Person Mobile" maxlength="10"
                        [ngClass]="{'select-option-disabled':isContactPersonPresent}" required>
                      <label>Contact Person Mobile <em class="required">*</em></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- <div class="button-section">
        <ng-container>
          <button type="submit" class="submit-button" (click)="submitFinancierForm()">
            Save Financier
          </button>
        </ng-container>
        <ng-container *ngIf="isFinancierLenderPresent">
          <button type="submit" class="update-button">Update Financier</button>
        </ng-container>
        <ng-container *ngIf="!isAddFinancierRequest && isAddFinancierRequest !=undefined">
          <button type="button" class="cancel-button" data-dismiss="modal">
            Cancel
          </button>
        </ng-container>

      </div> -->
    </form>
  </div>
</section>