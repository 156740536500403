import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataService } from '../../../data.service';
import { MyAccountService } from '../../../my-account/myAccount.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { ToastService } from '../../../services/toast.service';
import { LoadingService } from '../../../services/loader.service';
import moment from 'moment';

@Component({
  selector: 'app-kyc-doc',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, MatButtonModule, MatIconModule, MatTabsModule],
  providers: [DataService, MyAccountService],
  templateUrl: './kyc-doc.component.html',
  styleUrl: './kyc-doc.component.scss'
})
export class KycDocComponent {
  @Input('isSaved') isSaved = true;

  businessDetailsForm: FormGroup;
  contactDetailsForm: FormGroup;
  promotersDetailsForm: FormGroup;

  buyerKyc = [];
  businessNature: String;
  items: any;
  contactDetails: ContactDetails[] = [];
  basicDetailShouldDisplay: any = false;
  isCustomerKycDone: any = true;
  gstReport: any;
  gstregistration: any;
  partenershipDead: any
  tempdocname: any;
  pan: any;
  iec: any
  banckStatement: any;
  termLoan: any
  balancesheet: any
  itrReport: any
  docFiles: any = {};
  userId: string;
  gstReportUrl: any;
  gstregistrationUrl: any;
  partenershipDeadUrl: any;
  panUrl: any;
  iecUrl: any;
  banckStatementUrl: any;
  termLoanUrl: any;
  itrReportUrl: any;
  balancesheetUrl: any;

  constructor(
    private bd: FormBuilder,
    private router: Router,
    private accountService: MyAccountService,
    private dataService: DataService,
    private toasterService: ToastService,
    private loadingService: LoadingService,
    private route: ActivatedRoute
  ) {
    this.setInitForms();
    this.initContactDetails();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.userId = params['id'] || '';
    })
    if (this.userId != null) {
      this.getKycDataWithId(this.userId);
    }
    else {
      this.getKycData();
    }

  }

  setInitForms() {
    this.businessDetailsForm = this.bd.group({
      companyName: [null, Validators.required],
      dateOfCom: [null, Validators.required],
      incorporationDate: [null, Validators.required],
      zipCode: [null, Validators.required],
      panNo: [null, Validators.required],
      state: [null, Validators.required],
      address: [null, Validators.required],
      gstin: [null, Validators.required],
      businessInvolved: [null, Validators.required],
      businessNature: [null, Validators.required],
      iecNumber: [null, Validators.required],
      vinatageInBusiness: [null, Validators.required],
      turnOver: [null, Validators.required],
    });

    this.promotersDetailsForm = this.bd.group({
      promoterName: [null, Validators.required],
      kycNumber: [null, Validators.required],
      associationDate: [null, Validators.required],
      dob: [null, Validators.required],
      state: [null, Validators.required],
      gender: [null, Validators.required],
      nationality: [null, Validators.required],
      residence: [null, Validators.required],
      address: [null, Validators.required],
      phoneNo: [null, Validators.required],
      zipCode: [null, Validators.required],
      email: [null, Validators.required],
    });
  }

  initContactDetails() {
    let tempObj = {
      fullName: null,
      phoneNumber: null,
      emailId: null
    };
    this.contactDetails.push(tempObj);
  }
  submitKycDoc() {
    let obj = {
      buyerKyc: this.businessDetailsForm.value,
      buyerContact: this.contactDetails,
      buyerPromoter: this.promotersDetailsForm.value,
      kycDocument: this.docFiles
    };
    if (Object.keys(this.docFiles).length == 9) {
      this.accountService.saveKycDetails(obj).subscribe((res) => {
        if (res) {
          this.toasterService.show('Success', res['message']);
        }
        else {
          this.toasterService.show('Error', res['message']);
        }
      });

    }
    else {
      this.toasterService.show('Error', 'Upload All the Documents');
    }
  }

  addContactPerson() {
    const newItem = {
      fullName: null,
      phoneNumber: null,
      emailId: null,
    }
    this.contactDetails.push(newItem);
  }

  removeContactPerson(index) {
    this.contactDetails.splice(index, 1);
  }
  onFileChanged(event: Event, DocumentName: string) {
    this.fileUpload(event, DocumentName)
      .then(result => {
        // Handle success
        console.log('File uploaded successfully:', result);
      })
      .catch(error => {
        // Handle error
        console.error('File upload failed:', error);
      });
  }

  fileUpload(event, DocName) {
    return new Promise((resolve, reject) => {
      let fileToUpload = event.target.files.item(0);
      let xhr = this.accountService.uploadDocs(fileToUpload, DocName);

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            let res = JSON.parse(xhr.responseText);
            // Assign the URL to the corresponding DocName key in the docFiles object
            this.docFiles[DocName] = res['url'];
            resolve(this.docFiles);
          } else {
            reject(`Error uploading ${DocName}. Status: ${xhr.status}`);
          }
        }
      };
    });
  }

  getKycData() {

    this.accountService.getKycData().subscribe(data => {
      this.setFinancierData(data);

    })
  }

  getKycDataWithId(id?: string) {
    this.accountService.getKycDataWithId(id).subscribe(data => {
      this.setFinancierData(data);
    });
  }

  setFinancierData(data) {
    this.setBusinessDetailsForm(data.result.buyerKyc);
    this.setPromotersForm(data.result.buyerPromoter);
    this.setBuyerContactDetails(data.result.buyerContact);
    this.setDocumentCollectiondetails(data.result.kycDocument);
  }

  setBusinessDetailsForm(businessDetails) {
    businessDetails.dateOfCom = moment(new Date(businessDetails.dateOfCom)).format('yyyy-MM-DD');
    businessDetails.incorporationDate = moment(new Date(businessDetails.incorporationDate)).format('yyyy-MM-DD');
    this.businessDetailsForm.patchValue(businessDetails);
  }

  setPromotersForm(promoterDetails) {
    promoterDetails.associationDate = moment(new Date(promoterDetails.associationDate)).format('yyyy-MM-DD');
    promoterDetails.dob = moment(new Date(promoterDetails.dob)).format('yyyy-MM-DD')
    this.promotersDetailsForm.patchValue(promoterDetails);
  }

  setBuyerContactDetails(contactDetails) {
    this.contactDetails = contactDetails;
  }

  setDocumentCollectiondetails(documentCollection) {
    this.gstReportUrl = documentCollection.gstReport;
    this.gstregistrationUrl = documentCollection.gstRegistration;
    this.partenershipDeadUrl = documentCollection.moa;
    this.panUrl = documentCollection.panDoc;
    this.iecUrl = documentCollection.iecCertificate;
    this.banckStatementUrl = documentCollection.bankStatement;
    this.termLoanUrl = documentCollection.termLoanDetail;
    this.itrReportUrl = documentCollection.itrReport;
    this.balancesheetUrl = documentCollection.auditBS;
  }

  removePromoters() {

  }

  addPromoters() {

  }

  clearStack(event) {
    event.target.value = null;
  }

  kycComp() {
    this.router.navigate([`customer-list/${this.userId}`]);
  }
  downloadFile(url, docName) {
    const link = document.createElement('a');
    link.href = url;
    link.download = docName; // Set a meaningful filename
    link.click();
  }
}

export interface BuyerKyc {
  buyerContact: BuyerContact;
  buyerPromoter: BuyerPromoter;
  kycDocument: KycDocument;
}

export interface BuyerContact {
  companyName: string;
  incorporationDate: number;
  dateOfCom: number;
  entityNature: string;
  businessNature: string;
  panNo: string;
  gstin: string;
  address: string;
  state: string;
  zipCode: string;
  businessInvolved: string;
  iecNo: string;
  turnover: number;
}

export interface BuyerPromoter {
  name: string;
  kycNo: string;
  associationDate: number;
  dob: number;
  gender: string;
  nationality: string;
  residence: string;
  address: string;
  state: string;
  zipCode: number;
  phoneNo: number;
  email: string;
  panNo: string;
}

export interface KycDocument {
  gstReport: string;
  gstRegistration: string;
  moa: string;
  panDoc: string;
  iecCertificate: string;
  bankStatement: string;
  termLoanDetail: string;
  auditBS: string;
  itrReport: string;
}
interface ContactDetails {
  fullName: string | null;
  phoneNumber: string | null;
  emailId: string | null;
}
